<template>
  <div class="income_warp">
    <div class="header">
      <div class="back" @click="$router.go(-1)">
        <svg-icon icon-class="back"></svg-icon>
      </div>
      <h2 class="ellipsis">收益</h2>
      <router-link class="right-icon" to="/incomeCenter">
       <span>提现</span>
      </router-link>
    </div>
    <div class="content">
      <div class="item" v-for="i in 12" :key="i">
        <div class="item_info">
          <div class="cover">
            <ImgDecypt src="image/15z/w6/14b/2ke/b776c04915cad47ceb17b2dd89051768.png" round/>
          </div>
          <div class="right_info">
            <div class="name_box">
              <div class="name ellipsis">用户名</div>
              <div class="unlock_name">解锁了你的帖子</div>
            </div>
            <div class="gold">
              <svg-icon icon-class="add4"/>
              99金币
            </div>
          </div>
        </div>
        <p class="ellipsis text">帖子文案 ——偷窺〉經常幻想和小婷經常幻想和小婷小婷經常幻想和小婷</p>
      </div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from "@/components/ImgDecypt";

export default {
  components: {
    ImgDecypt,
  }
}
</script>
<style lang="scss" scoped>
.income_warp {
  height: 100%;

  .header {
    display: flex;
    height: 44px;
    align-items: center;
    justify-content: center;
    position: relative;

    .back {
      position: absolute;
      left: 20px;
      font-size: 24px;
    }

    h2 {
      font-size: 17px;
      max-width: 300px;

    }

    .right-icon {
      position: absolute;
      right: 20px;
      width: 79px;
      height: 32px;
      line-height: 32px;
      text-align: center;
      background: #ff7527;
      border-radius: 20px;
      font-size: 14px;
      color: #ffffff;
    }
  }

  .content {
    padding: 0 16px;
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .item {
      padding-top: 20px;

      .item_info {
        display: flex;

        .cover {
          width: 43px;
          height: 43px;
        }

        .right_info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-left: 10px;
          flex: 1;

          .name_box {
            display: flex;

            .name {
              font-size: 14px;
              color: #ffffff;
              max-width: 180px;
            }

            .unlock_name {
              padding-left: 5px;
              color: #5e5e5e;
            }
          }

          .gold {
            color: #f6d85d;
          }
          .lower {
            color: #d81e06;
          }
        }
      }

      .text {
        padding-top: 14px;
        font-size: 14px;
        font-family: "PingFangHK-Light";
      }
    }
  }
}
</style>
